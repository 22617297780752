import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableNotificationTemplate() {
  // Use toast
  const toast = useToast()

  const refNotificationTemplateTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('TitleBooking.NotificationTemplate') }),
    code: `${t('FormNotificationTemplate.Code')} » ${t('ID')}`,
    message: `${t('FormNotificationTemplate.Message')}`,
    sub_message: `${t('FormNotificationTemplate.SubMessage')}`,
    status: `${t('FormNotificationTemplate.Status')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'code', sortable: true, label: msgList.code },
    { key: 'message', sortable: false, label: msgList.message },
    { key: 'sub_message', sortable: true, label: msgList.sub_message },
    { key: 'status', sortable: true, label: msgList.status },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalNotificationTemplates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refNotificationTemplateTable.value ? refNotificationTemplateTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalNotificationTemplates.value,
    }
  })

  const refetchData = () => {
    if (refNotificationTemplateTable.value !== null) {
      refNotificationTemplateTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getListNotificationTemplate = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      lineOaId: id,
    }
    store
      .dispatch('store-notification-template/getListNotificationTemplate', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalNotificationTemplates.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-notification-template`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const isStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'danger'
    if (status === 'pending') return 'warning'
    return 'secondary'
  }

  const isStatusToText = isstatus => {
    if (isstatus === 'active') return 'Active'
    if (isstatus === 'inactive') return 'Inactive'
    return ''
  }

  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  return {
    getListNotificationTemplate,
    tableColumns,
    perPage,
    currentPage,
    totalNotificationTemplates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refNotificationTemplateTable,

    refetchData,
    isStatusVariant,
    isStatusToText,
    textFirstUpper,
  }
}
