import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultNotificationTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_notification_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListNotificationTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_notification_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getNotificationTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_notification_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNotificationTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_notification_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editNotificationTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_notification_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteNotificationTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_notification_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getNotificationTemplateOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/notification_template_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
