import { render, staticRenderFns } from "./NotificationTemplateForm.vue?vue&type=template&id=baba3068&scoped=true&"
import script from "./NotificationTemplateForm.vue?vue&type=script&lang=js&"
export * from "./NotificationTemplateForm.vue?vue&type=script&lang=js&"
import style0 from "./NotificationTemplateForm.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./NotificationTemplateForm.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./NotificationTemplateForm.vue?vue&type=style&index=2&id=baba3068&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baba3068",
  null
  
)

export default component.exports