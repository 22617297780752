<template>
  <b-modal
    id="form-notification-template"
    :visible="shallShowNotificationTemplateFormModal"
    :title="`${$t('Form')} ${$t('TitleBooking.NotificationTemplate')}`"
    footer-class="d-flex justify-content-between"
    size="lg"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-notification-template-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeNotificationTemplateForm)} ${$t('TitleBooking.NotificationTemplate')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-notification-template-form-modal', false) : false"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeNotificationTemplateForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typeNotificationTemplateForm === 'Add' || typeNotificationTemplateForm === 'Edit') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <!-- Field: Code -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeNotificationTemplateForm === 'Add' || typeNotificationTemplateForm === 'Edit')"
                      :label="$t('Code')"
                      label-for="notification-template-code"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Code')"
                        rules="required"
                      >
                        <b-form-input
                          id="notification-template-code"
                          v-model="notificationTemplateData.code"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Code')"
                      label-for="notification-template-code"
                    >
                      <span class="form-info-box">{{ notificationTemplateData.code }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Status -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeNotificationTemplateForm === 'Add' || typeNotificationTemplateForm === 'Edit')"
                      :label="$t('Status')"
                      label-for="notification-template-status"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Status')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="notificationTemplateData.status"
                          name="notification-template-status"
                          value="active"
                          class="custom-control-success cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Active') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="notificationTemplateData.status"
                          name="notification-template-status"
                          value="inactive"
                          class="custom-control-danger cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Status')"
                      label-for="notification-template-status"
                    >
                      <span class="form-info-box">
                        <b-badge
                          pill
                          :variant="`light-${isStatusVariant(notificationTemplateData.status)}`"
                          class="text-capitalize"
                        >
                          {{ $t(textFirstUpper(notificationTemplateData.status)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Field: Message -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeNotificationTemplateForm === 'Add' || typeNotificationTemplateForm === 'Edit')"
                      :label="$t('Message')"
                      label-for="notification-template-message"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Message')"
                        rules="required"
                      >
                        <div class="message-editor">
                          <quill-editor
                            id="quil-content-message"
                            ref="refMessage"
                            v-model="notificationTemplateData.message"
                            :options="messageOption"
                            :state="getValidationState(validationContext)"
                            :disabled="busy"
                          />
                          <div
                            id="quill-toolbar-message"
                            class="d-flex border-top-0"
                          >
                            <!-- <button class="ql-bold" />
                            <button class="ql-italic" />
                            <button class="ql-underline" />
                            <button class="ql-align" />
                            <button class="ql-link" />
                            <select class="ql-size">
                              <option value="small" />
                              <option selected />
                              <option value="large" />
                              <option value="huge" />
                            </select> -->
                            <span
                              v-for="more in notificationTemplateData.more_message_option"
                              :key="more.value"
                              class="cursor-pointer"
                              style="margin-left: 0.2rem; border: 1px solid #aaaaaa !important; color: #000000;
                                border-radius: 0.2rem; padding: 0.2rem 0.4rem 0.2rem 0.4rem; font-size: 12px;"
                              @click="onAddMessageMore(more.value)"
                            >
                              <feather-icon icon="PlusIcon" />
                              {{ more.label }}
                            </span>
                          </div>
                        </div>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Message')"
                      label-for="notification-template-message"
                    >
                      <quill-editor
                        v-model="notificationTemplateData.message"
                        :options="messageViewOption"
                        :disabled="true"
                        style="border-bottom: 1px solid #cccccc !important;"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Field: SubMessage -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeNotificationTemplateForm === 'Add' || typeNotificationTemplateForm === 'Edit')"
                      :label="$t('FormNotificationTemplate.SubMessage')"
                      label-for="notification-template-message"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormNotificationTemplate.SubMessage')"
                        rules="required"
                      >
                        <div class="sub-message-editor">
                          <quill-editor
                            id="quil-content-sub-message"
                            ref="refSubMessage"
                            v-model="notificationTemplateData.sub_message"
                            :options="subMessageOption"
                            :state="getValidationState(validationContext)"
                            :disabled="busy"
                          />
                          <div
                            id="quill-toolbar-sub-message"
                            class="d-flex border-top-0"
                          >
                            <span
                              v-for="more in notificationTemplateData.more_message_option"
                              :key="more.value"
                              class="cursor-pointer"
                              style="margin-left: 0.2rem; border: 1px solid #aaaaaa !important; color: #000000;
                                border-radius: 0.2rem; padding: 0.2rem 0.4rem 0.2rem 0.4rem; font-size: 12px;"
                              @click="onAddSubMessageMore(more.value)"
                            >
                              <feather-icon icon="PlusIcon" />
                              {{ more.label }}
                            </span>
                          </div>
                        </div>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormNotificationTemplate.SubMessage')"
                      label-for="notification-template-sub-message"
                    >
                      <quill-editor
                        v-model="notificationTemplateData.sub_message"
                        :options="subMessageViewOption"
                        :disabled="true"
                        style="border-bottom: 1px solid #cccccc !important;"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          notification-template="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormInput,
  BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { quillEditor } from 'vue-quill-editor'
import tableNotificationTemplate from './tableNotificationTemplate'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormInput,
    BFormRadio,

    quillEditor,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowNotificationTemplateFormModal',
    event: 'update:shall-show-notification-template-form-modal',
  },
  props: {
    shallShowNotificationTemplateFormModal: {
      type: Boolean,
      required: true,
    },
    typeNotificationTemplateForm: {
      type: String,
      default: 'Add',
    },
    notificationTemplateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      subMessageOption: {
        modules: {
          toolbar: '#quill-toolbar-sub-message',
        },
        placeholder: this.$i18n.t('SubMessage'),
      },
      messageOption: {
        modules: {
          toolbar: '#quill-toolbar-message',
        },
        placeholder: this.$i18n.t('Message'),
      },
      subMessageViewOption: {
        modules: {
          toolbar: false,
        },
      },
      messageViewOption: {
        modules: {
          toolbar: false,
        },
      },
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeNotificationTemplateForm === 'Add') {
        this.notificationTemplateData.line_oa_id = this.lineOaId
        store.dispatch('store-notification-template/addNotificationTemplate', this.notificationTemplateData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$swal({
              icon: 'success',
              title: this.$i18n.t('Added'),
              text: this.$i18n.t('Your data has been added'),
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

            this.$emit('notification-template-form-update')
            this.$emit('update:shall-show-notification-template-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeNotificationTemplateForm === 'Edit') {
        store.dispatch('store-notification-template/editNotificationTemplate', { id: this.notificationTemplateData.id, data: this.notificationTemplateData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('notification-template-form-update')
            this.$emit('update:shall-show-notification-template-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeNotificationTemplateForm === 'Delete') {
        store.dispatch('store-notification-template/deleteNotificationTemplate', {
          code: this.notificationTemplateData.code,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('notification-template-form-update')
            this.$emit('update:shall-show-notification-template-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-notification-template-form')
      this.$emit('update:shall-show-notification-template-form-modal', false)
    },
    onAddSubMessageMore(text) {
      if (this.$refs.refSubMessage !== undefined) {
        if (this.$refs.refSubMessage.quill) {
          let index = 0
          this.$refs.refSubMessage.quill.focus()
          const range = this.$refs.refSubMessage.quill.getSelection()
          if (range) {
            if (range.length === 0) {
              index = range.index
            } else {
              index = range.index
            }
          } else {
            index = 0
          }
          this.$refs.refSubMessage.quill.insertText(index, text, 'normal', true)
        }
      }
    },
    onAddMessageMore(text) {
      if (this.$refs.refMessage !== undefined) {
        if (this.$refs.refMessage.quill) {
          let index = 0
          this.$refs.refMessage.quill.focus()
          const range = this.$refs.refMessage.quill.getSelection()
          if (range) {
            if (range.length === 0) {
              index = range.index
            } else {
              index = range.index
            }
          } else {
            index = 0
          }
          this.$refs.refMessage.quill.insertText(index, text, 'normal', true)
        }
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      textFirstUpper,
      isStatusVariant,
    } = tableNotificationTemplate()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      textFirstUpper,
      isStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {
  // Quill Editor Style
  .quill-editor {
    //.ql-editor {
    //  min-height: 200px;
    //}
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
  .ql-toolbar {
    border: 1px solid #000000;
    border-radius: 0;
    border-top: 0px solid #000000;
    border-bottom: 1px solid #000000;
    .ql-picker.ql-expanded .ql-picker-options {
      bottom: 100%;
      top: auto;
    }
  }
}

</style>
